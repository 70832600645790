import React from "react";
import { motion } from "framer-motion";
import { myInfo, myTech } from "../../assets/data";
import { PageContainer } from "../../subComponents/pageContainer";
import JD from "../../assets/images/portfolio.jpg";
import {
  ContentContainer,
  ImageContainer,
  TitleContainer,
  ParagraphContainer,
  TechContainer,
  TechWrapper,
} from "./aboutStyles";
import { aboutImageVariant, contentVariant } from "./aboutVariants";

const About = () => {
  return (
    <PageContainer
      as={motion.div}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* -----------About Page Content */}
      <ContentContainer
        variants={contentVariant}
        initial="hidden"
        animate="visible"
      >
        <TitleContainer
          as={motion.div}
          variants={contentVariant}
          initial="hidden"
          animate="visible"
        >
          <h2>Something About me</h2>
        </TitleContainer>

        <ParagraphContainer
          as={motion.div}
          variants={contentVariant}
          initial="hidden"
          animate="visible"
        >
          <p>{myInfo.story.description}</p>
        </ParagraphContainer>

        <TechContainer>
          {myTech.map((tech, index) => {
            return <TechWrapper key={index}>{tech}</TechWrapper>;
          })}
        </TechContainer>
      </ContentContainer>

      {/* -----------Profile Image */}
      <ImageContainer
        as={motion.div}
        variants={aboutImageVariant}
        initial="hidden"
        animate="visible"
      >
        <img src={JD} alt="Man playing a guitar" />
      </ImageContainer>
    </PageContainer>
  );
};

export default About;
