import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import {
  ContactForm,
  ContactFormContainer,
  TextArea,
  SubmitButton,
  ContactImageContainer,
} from "./contactStyles";

import { contactFormVariant, contactImageVariant } from "./contactVariants";

import Kovvi from "../../assets/images/phone3.png";

import { PageContainer } from "../../subComponents/pageContainer";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        `${process.env.REACT_APP_SERVICE_ID}`,
        `${process.env.REACT_APP_TEMPLATE_ID}`,
        form.current,
        `${process.env.REACT_APP_PUBLIC_ID}`
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    form.current.reset();
  };

  return (
    <PageContainer
      as={motion.div}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <ContactFormContainer
        as={motion.div}
        variants={contactFormVariant}
        initial="hidden"
        animate="visible"
      >
        <ContactForm
          ref={form}
          onSubmit={sendEmail}
          as={motion.form}
          variants={contactFormVariant}
          initial="hidden"
          animate="visible"
        >
          <h2>Get in touch</h2>
          <input type="text" placeholder="Name" name="name" />
          <input type="email" placeholder="Email" name="email" />
          <TextArea type="text" placeholder="Message" name="message" />
          <SubmitButton type="submit">Submit</SubmitButton>
        </ContactForm>

        <ContactImageContainer
          as={motion.div}
          variants={contactImageVariant}
          initial="hidden"
          animate="visible"
        >
          <motion.img src={Kovvi} alt="Phone" />
        </ContactImageContainer>
      </ContactFormContainer>
    </PageContainer>
  );
};

export default Contact;
